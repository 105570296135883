import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetEntity, SetEntity } from './entity-store.actions';

@State<any>({
  name: 'entity',
  defaults: null
})
@Injectable()
export class EntityStore {

  @Action(SetEntity)
  setEntity(
    { setState }: StateContext<any>,
    { payload }: any
  ): void {
    setState(payload);
  }

  @Action(ResetEntity)
  resetEntity(
    { setState }: StateContext<any>
  ): void {
    setState(null);
  }
}
