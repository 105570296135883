import { IVenueDetailsStore } from './venue-store.model';

export class SetVenueDetails {
  static readonly type = '[VENUE] set venue details';
  constructor(
    public payload: IVenueDetailsStore
  ) { }
}

export class ResetVenueDetails {
  static readonly type = '[VENUE] reset venue details';
  constructor() { }
}
