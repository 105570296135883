import { AddOnsPageModule } from './pages/main/children/account/children/menu/children/add-ons/add-ons.module';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
}, {
  path: 'main/account/edit-account-profile',
  loadChildren: () => import('./pages/main/children/account/children/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
}, {
  path: 'main/account/venue-settings',
  loadChildren: () => import('./pages/main/children/account/children/venue-settings/venue-settings.module').then(m => m.VenueSettingsPageModule)
}, {
  path: 'main/account/menu',
  loadChildren: () => import('./pages/main/children/account/children/menu/menu.module').then(m => m.MenuPageModule)
}, {
  path: 'main/account/menu/category',
  loadChildren: () => import('./pages/main/children/account/children/menu/children/category/category.module').then(m => m.CategoryModule)
}, {
  path: 'main/account/menu/add-on',
  loadChildren: () => import('./pages/main/children/account/children/menu/children/add-ons/add-ons.module').then(m => m.AddOnsPageModule)
}, {
  path: 'main/account/menu/category/:uid/foods',
  loadChildren: () => import('./pages/main/children/account/children/menu/children/food-list/food-list.module').then(m => m.FoodListModule)
}, {
  path: 'main/account/menu/category/:uid/foods/food',
  loadChildren: () => import('./pages/main/children/account/children/menu/children/food/food.module').then(m => m.FoodModule)
}, {
  path: 'main/account/drivers',
  loadChildren: () => import('./pages/main/children/account/children/driver-list/driver-list.module').then(m => m.DriverListPageModule)
}, {
  path: 'main/account/drivers/driver',
  loadChildren: () => import('./pages/main/children/account/children/driver/driver.module').then(m => m.DriverPageModule)
}, {
  path: 'main/account/reviews',
  loadChildren: () => import('./pages/main/children/account/children/reviews/reviews.module').then(m => m.ReviewsPageModule)
}, {
  path: 'order/:uid',
  loadChildren: () => import('./pages/main/children/orders/children/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
}, {
  path: 'navigate/:order-uid/:navigate',
  loadChildren: () => import('./pages/main/children/navigator/navigator.module').then(m => m.NavigatorPageModule)
}, {
  path: 'new-tables',
  loadChildren: () => import('./pages/main/children/tables/children/new-tables/new-tables.module').then(m => m.NewTablesModule)
}, {
  path: 'table/:uid',
  loadChildren: () => import('./pages/main/children/tables/children/table-detail/table-detail.module').then(m => m.TableDetailModule)
}, {
  path: 'table/:uid/order/:uid',
  loadChildren: () => import('./pages/main/children/tables/children/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
}, {
  path: 'login',
  loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
}, {
  path: 'forgot-password',
  loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
}];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
