import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ResetVenueDetails, SetVenueDetails } from './venue-store.actions';
import { IVenueDetailsStore } from './venue-store.model';

@State<IVenueDetailsStore>({
  name: 'venue',
  defaults: null
})
@Injectable()
export class VenueStore {

  @Action(SetVenueDetails)
  setVenue(
    { setState }: StateContext<IVenueDetailsStore>,
    { payload }: SetVenueDetails
  ): void {
    setState(payload);
  }

  @Action(ResetVenueDetails)
  resetVenue(
    { setState }: StateContext<IVenueDetailsStore>
  ): void {
    setState(null);
  }
}
