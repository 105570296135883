export class SetEntity {
  static readonly type = '[ENTITY] set entity';
  constructor(
    public payload: any
  ) { }
}

export class ResetEntity {
  static readonly type = '[ENTITY] reset entity';
  constructor() { }
}
